import Moment from "moment";
import DocImg from "../assets/Images/Icons/doc.png";
import PdfImg from "../assets/Images/Icons/pdf.png";
import XlsImg from "../assets/Images/Icons/xls.png";
import DefaultImage from "../assets/Images/Icons/no_image.png"
// import { Enums } from "../constants/Enums";
const cryptoKey = "eQ@InSuAance2025";


export const commonHelper = {
  setItem,
  getItem,
  getHeaders,
  getExtBaseFile,
  changeTimeFormate,
  formatDate,
  scrollToTop,
  validateUuid
};

function setItem(key, strString) {
  let CryptoJS = require("crypto-js");
  localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {
  let CryptoJS = require("crypto-js");
  let dataValues = localStorage.getItem(key) || "";
  let dataStr = "";
  if (dataValues !== "") {
    var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
    dataStr = bytes.toString(CryptoJS.enc.Utf8);
  }
  return dataStr;
}


function getHeaders() {
  if (getItem("auth_token")) {
    getItem("auth_token")
    return {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + getItem("auth_token"),
    }
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  }
}

function getExtBaseFile(url, ext = null) {
  let result = "";
  const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
  const pdfExtensions = ["pdf"];
  const xlsExtensions = ["xlsx", "xls"];
  const docExtensions = ["doc", "docx", "ppt", "pptx", "txt"];
  if (ext) {
    if (imgExtensions.includes(ext)) {
      result = url;
    } else if (docExtensions.includes(ext)) {
      result = DocImg;
    } else if (pdfExtensions.includes(ext)) {
      result = PdfImg;
    } else if (xlsExtensions.includes(ext)) {
      result = XlsImg;
    } else {
      result = DefaultImage;
    }
  } else {
    result = DefaultImage;
  }

  return result;
};

function changeTimeFormate(time, format = '') {
  let localDate = '';
  if (time !== null && time !== "" && time !== undefined) {
    // let stillUtc = Moment.utc(date).toDate();
    localDate = Moment.utc(time, 'HH:mm:ss').format(format !== '' ? format : 'HH:mm:ss');
  }
  return localDate;
}

function formatDate(date, format = 'MM-DD-YYYY', type = 'utc_to_local') {

  let localDate = '';
  if (date !== null && date !== "" && date !== undefined) {
    if (type === 'utc_to_local') {

      localDate = Moment(date).local().format(format);

    } else if (type === 'local_to_utc') {

      localDate = Moment(date).utc().format(format);

    } else {

      localDate = Moment(date).format(format);

    }
  }
  return localDate;
}

function scrollToTop() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 250);
}

function validateUuid(str) {
  let pattern = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  return pattern.test(str) ? true : false

}