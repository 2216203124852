import React, { useState } from "react";
import {
  Form,
  Card,
} from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
import Routes from "../../routes";
import { commonHelper } from "../../common/commonHelper";
import InputElement from "../../components/Forms/InputElement";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { alerts } from "../../common/alertService";
import ButtonLoading from "../../components/Forms/ButtonLoading";
import SelectInput from "../../components/Forms/SelectInput";
import { Options } from "../../constants/Options";

export default function AgentAdd() {
  document.title = `${process.env.REACT_APP_NAME} | Agent List`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    alternate_phone_number: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    termination_date:"",
    password: "",
    confirm_password: "",
  })

  const [formInputsErrors, setFormInputsErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    termination_date:"",
    password: "",
    confirm_password: "",
  })

  const validationError = {
    first_name: "First name is required",
    last_name: "Last name is required",
    email: "Email is required",
    phone_number: "Phone number is required",
    date_of_birth: "Date of birth is required",
    gender: "Gender is required",
    marital_status: "Marital status is required",
    termination_date: "Termination Date is required",
    password: "Password is required",
    confirm_password: "Confirm Password is required",
    validate_email: "Email is not valid",
    mismatch_password: 'password and confirm password must be same'
  }

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((er_key) => {
      errorJson = {
        ...errorJson,
        [er_key]: ""
      }
    })

    Object.keys(errorJson).forEach((form_er_key) => {

      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "email") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.email)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })

    if (isValidate) {
      if (formInputs['confirm_password'] !== formInputs['password']) {
        errorJson = {
          ...errorJson,
          'confirm_password': validationError.mismatch_password
        }
        isValidate = false;
      }
    }
    setFormInputsErrors(errorJson)
    return isValidate;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true)
      let res = await APICall({
        url: ApiTypes.agentStore,
        data: formInputs
      })
      if (res) {
        if (res.status === 1) {
          alerts.notifySuccess(res.message)
          navigate(Routes.AgentList.path)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const handleOnChange = (name, value) => {
    setFormInputs({
      ...formInputs,
      [name]: value
    })
  }

  return (<>
    <Card className="p-4 mb-4">
      <div className='container-fluid p-0'>
        <div className="row d-flex justify-content-between align-items-center"><div className="col-4"></div>
          <div className="col-2 d-flex justify-content-end"></div>
        </div>
        <div className='col-4 pb-4'>
          <div className="h6">Add Agent</div>
        </div>

        <Form onSubmit={(e) => handleSubmit(e)} className="mt-4">
          <div className="row g-4">
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'First Name'}
              value={formInputs.first_name}
              name="first_name"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="First Name"
              validateError={formInputsErrors.first_name}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Last Name'}
              value={formInputs.last_name}
              name="last_name"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="Last Name"
              validateError={formInputsErrors.last_name}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Email Address'}
              value={formInputs.email}
              name="email"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="Email Address"
              validateError={formInputsErrors.email}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Phone Number'}
              value={formInputs.phone_number}
              name="phone_number"
              maxLength={16}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="number"
              placeholder="Phone Number"
              validateError={formInputsErrors.phone_number}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'Alternate Phone Number'}
              value={formInputs.alternate_phone_number}
              name="alternate_phone_number"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              maxLength={16}
              type="number"
              placeholder="Alternate Phone Number"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Date Of Birth'}
              value={formInputs.date_of_birth}
              name="date_of_birth"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="date"
              max={commonHelper.formatDate(new Date(), 'YYYY-MM-DD', '')}
              placeholder="Date Of Birth"
              validateError={formInputsErrors.date_of_birth}
            />
            <SelectInput
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              placeholder={'Select Gender'}
              selected={Options.genders.filter((itm) => itm.value === formInputs.gender)[0]}
              Label={"Select Gender"}
              options={Options.genders}
              onChange={(item) => {
                handleOnChange('gender', item.value)
              }}
              validateError={formInputsErrors.gender}
            />
            <SelectInput
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              placeholder={'Select Marital Status'}
              selected={Options.marital_status.filter((itm) => itm.value === formInputs.marital_status)[0]}
              Label={"Select Marital Status"}
              options={Options.marital_status}
              onChange={(item) => {
                handleOnChange('marital_status', item.value)
              }}
              validateError={formInputsErrors.marital_status}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'Address'}
              value={formInputs.address}
              name="address"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="Address"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'City'}
              value={formInputs.city}
              name="city"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="City"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'State'}
              value={formInputs.state}
              name="state"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="State"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'Country'}
              value={formInputs.country}
              name="country"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="Country"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              Label={'ZIP Code'}
              value={formInputs.zipcode}
              name="zipcode"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="text"
              placeholder="ZIP Code"
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Termination Date'}
              value={formInputs.termination_date}
              name="termination_date"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="date"
              max={commonHelper.formatDate(new Date(), 'YYYY-MM-DD', '')}
              placeholder="Termination Date"
              validateError={formInputsErrors.termination_date}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Password'}
              value={formInputs.password}
              name="password"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="password"
              placeholder="Password"
              validateError={formInputsErrors.password}
            />
            <InputElement
              inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
              is_required={true}
              Label={'Confirm Password'}
              value={formInputs.confirm_password}
              name="confirm_password"
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              type="password"
              placeholder="Confirm Password"
              validateError={formInputsErrors.confirm_password}
            />
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <ButtonLoading
                loading={loading}
                innerText={'Submit'}
                className="w-auto"
              />
            </div>

          </div>

        </Form>

      </div>
    </Card></>)
}
