import React, { useEffect, useState } from "react";
import { Form, Card } from "@themesberg/react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import InputElement from "../../components/Forms/InputElement";
import SelectInput from "../../components/Forms/SelectInput";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import ButtonLoading from "../../components/Forms/ButtonLoading";
import { Options } from "../../constants/Options";
import { commonHelper } from "../../common/commonHelper";
import { alerts } from "../../common/alertService";
import Routes from "../../routes";

export default function AgentEdit() {
  document.title = `${process.env.REACT_APP_NAME} | Agent Edit`;
  const navigate = useNavigate();

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    alternate_phone_number: '',
    date_of_birth: '',
    gender: '',
    marital_status: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    termination_date:'',
    password: '',
    confirm_password: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await APICall({
        url: ApiTypes.agentDetails.replace(':id', id),
        method: 'GET',
      });
      if (response && response.status === 1) {
        console.log("res",response)
        const displayNameParts = response.data.display_name.split(' ');
        const firstName = displayNameParts[0];
        const lastName = displayNameParts.slice(1).join(' ');
        setFormInputs({
          first_name: firstName,
          last_name: lastName,
          email: response.data.email,
          phone_number: response.data.phone,
          alternate_phone_number: response.data.phone,
          date_of_birth: response.data.date_of_birth,
          gender: response.data.gender,
          marital_status: response.data.maritial_status,
          address: response.data.address,
          city: response.data.city,
          state: response.data.state,
          country: response.data.country,
          zipcode: response.data.zipcode,
          termination_date:response.data.termination_date,
          password: '', // Assuming password is not editable
          confirm_password: '' // Assuming confirm password is not editable
        });
      }
    };
    fetchData();
  }, [id]);

  const [formInputsErrors, setFormInputsErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    termination_date:"",
  })

  const validationError = {
    first_name: "First name is required",
    last_name: "Last name is required",
    email: "Email is required",
    phone_number: "Phone number is required",
    date_of_birth: "Date of birth is required",
    gender: "Gender is required",
    marital_status: "Marital status is required",
    termination_date: "Termination Date is required",
    validate_email: "Email is not valid",
    mismatch_password: 'password and confirm password must be same'
  }

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((er_key) => {
      errorJson = {
        ...errorJson,
        [er_key]: ""
      }
    })

    Object.keys(errorJson).forEach((form_er_key) => {

      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "email") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.email)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })

    if (isValidate) {
      if (formInputs['confirm_password'] !== formInputs['password']) {
        errorJson = {
          ...errorJson,
          'confirm_password': validationError.mismatch_password
        }
        isValidate = false;
      }
    }
    setFormInputsErrors(errorJson)
    return isValidate;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true)
      let res = await APICall({
        url: ApiTypes.agentUpdate.replace(':id', id),
        method: 'PUT',
        data: formInputs,
      })
      if (res) {
        if (res.status === 1) {
          alerts.notifySuccess(res.message)
          navigate(Routes.AgentList.path)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const handleOnChange = (name, value) => {
    setFormInputs({
      ...formInputs,
      [name]: value
    })
  }

  return (
    <Card className="p-4 mb-4">
        <Form onSubmit={(e) => handleSubmit(e)} className="mt-4">
        <div className="row g-4">
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            Label="First Name"
            value={formInputs.first_name}
            name="first_name"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="text"
            placeholder="First Name"
            validateError={formInputsErrors.first_name}
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            Label="Last Name"
            value={formInputs.last_name}
            name="last_name"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="text"
            placeholder="Last Name"
            validateError={formInputsErrors.last_name}
          />
        
          <InputElement
          
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            Label="Email Address"
            value={formInputs.email}
            name="email"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="text"
            placeholder="Email Address"
            validateError={formInputsErrors.email}
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            Label="Phone Number"
            value={formInputs.phone_number}
            name="phone_number"
            maxLength={16}
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="number"
            placeholder="Phone Number"
            validateError={formInputsErrors.phone_number}
            />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="Alternate Phone Number"
            value={formInputs.alternate_phone_number}
            name="alternate_phone_number"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            maxLength={16}
            type="number"
            placeholder="Alternate Phone Number"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            Label="Date Of Birth"
            value={formInputs.date_of_birth}
            name="date_of_birth"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="date"
            max={commonHelper.formatDate(new Date(), 'YYYY-MM-DD', '')}
            placeholder="Date Of Birth"
            validateError={formInputsErrors.date_of_birth}
          />
          <SelectInput
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            placeholder="Select Gender"
            selected={Options.genders.filter((itm) => itm.value === formInputs.gender)}
            Label="Select Gender"
            options={Options.genders}
            onChange={(item) => {
              handleOnChange('gender', item.value)
            }}
            validateError={formInputsErrors.gender}
          />
          <SelectInput
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={true}
            placeholder="Select Marital Status"
            selected={Options.marital_status.filter((itm) => itm.value === formInputs.marital_status)}
            Label="Select Marital Status"
            options={Options.marital_status}
            onChange={(item) => {
              handleOnChange('marital_status', item.value)
            }}
            validateError={formInputsErrors.marital_status}
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="Address"
            value={formInputs.address}
            name="address"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="text"
            placeholder="Address"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="City"
            value={formInputs.city}
            name="city"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="text"
            placeholder="City"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="State"
            value={formInputs.state}
            name="state"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="text"
            placeholder="State"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="Country"
            value={formInputs.country}
            name="country"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="text"
            placeholder="Country"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            Label="ZIP Code"
            value={formInputs.zipcode}
            name="zipcode"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="text"
            placeholder="ZIP Code"
          />
          <InputElement
            inputGroupClass={'col-sm-12 col-md-6 col-xl-6 col-lg-6'}
            is_required={true}
            Label='Termination Date'
            value={formInputs.termination_date}
            name="termination_date"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            type="date"
            max={commonHelper.formatDate(new Date(), 'YYYY-MM-DD', '')}
            placeholder="Termination Date"
            validateError={formInputsErrors.termination_date}
          />  
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={false}
            Label="Password"
            value={formInputs.password}
            name="password"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="password"
            placeholder="Password"
          />
          <InputElement
            inputGroupClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
            is_required={false}
            Label="Confirm Password"
            value={formInputs.confirm_password}
            name="confirm_password"
            onChange={(name, value) => {
              handleOnChange(name, value)
            }}
            
            type="password"
            placeholder="Confirm Password"
          />
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <ButtonLoading
              loading={loading}
              innerText="Submit"
              className="w-auto"
            />
          </div>
        </div>
      </Form>
    </Card>
  );
}
