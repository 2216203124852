import React, { useEffect, useState } from "react";
import {
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  FormCheck,
  Container,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Routes from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import InputElement from "../../components/Forms/InputElement";
import { useDispatch } from "react-redux";
import APICall from '../../networking/AxiousServices'
import { commonHelper } from "../../common/commonHelper";
import { alerts } from "../../common/alertService";
import ApiTypes from "../../networking/APItypes";
import ButtonLoading from "../../components/Forms/ButtonLoading";

export default function Signin() {

  document.title = `${process.env.REACT_APP_NAME} | Signin`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState({
    email: "",
    password: "",
    is_remember: false,
    current_date: new Date()
  })

  const [formInputsErrors, setFormInputsErrors] = useState({
    email: "",
    password: ""
  })
  const validationError = {
    email: "Email is required",
    password: "Password is required",
    validate_email: "Email is not valid",
  }

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((er_key) => {
      errorJson = {
        ...errorJson,
        [er_key]: ""
      }
    })
    Object.keys(errorJson).forEach((form_er_key) => {

      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "email") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.email)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })

    setFormInputsErrors(errorJson)
    return isValidate;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      let res = await APICall({
        url: ApiTypes.login,
        data: formInputs
      })
      if (res) {
        if (res.status === 1) {
          commonHelper.setItem("auth_token", res.data.token);
          commonHelper.setItem("user_id", res.data.user_id)
          commonHelper.setItem("user", JSON.stringify(res.data))
          dispatch({ type: 'USER_ID', payload: res.data.user_id })
          dispatch({ type: 'AUTH_TOKEN', payload: res.data.token })
          dispatch({ type: 'LOGGED_USER', payload: res.data })
          if (formInputs.is_remember) {
            commonHelper.setItem("remember_data", JSON.stringify(formInputs));
          } else {
            localStorage.removeItem("remember_data")
          }
          alerts.notifySuccess(res.message)
          navigate(Routes.DashboardOverview.path)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const handleOnChange = (name, value) => {
    setFormInputs({
      ...formInputs,
      [name]: value
    })
  }

  useEffect(() => {

    let remember_data = commonHelper.getItem("remember_data") ? JSON.parse(commonHelper.getItem("remember_data")) : null

    if (remember_data && remember_data.is_remember) {
      setFormInputs(prevFormInputs => ({
        ...prevFormInputs,
        email: remember_data.email,
        password: remember_data.password,
        is_remember: remember_data.is_remember
      }));
    } else {
      localStorage.removeItem("remember_data")
    }
  }, []);

  return (
    <Container>
      <Row
        className="justify-content-center form-bg-image"
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h3 className="mb-0">Sign in to our platform</h3>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)} className="mt-4">
              <InputElement
                Label={'Your Email'}
                value={formInputs.email}
                name="email"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                inputIcon={faEnvelope}
                type="text"
                placeholder="example@company.com"
                validateError={formInputsErrors.email}
              />
              <Form.Group>
                <InputElement
                  Label={'Your Password'}
                  value={formInputs.password}
                  onChange={(name, value) => {
                    handleOnChange(name, value)
                  }}
                  name="password"
                  inputIcon={faUnlockAlt}
                  type="password"
                  placeholder="Password"
                  validateError={formInputsErrors.password}
                />
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <Form.Check type="checkbox">
                    <FormCheck.Input onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        is_remember: e.target.checked ? e.target.checked : false,
                      });
                    }} checked={formInputs.is_remember ? formInputs.is_remember : false} id="defaultCheck5" className="me-2" />
                    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                      Remember me
                    </FormCheck.Label>
                  </Form.Check>
                  <Card.Link as={Link}
                    to={Routes.ForgotPassword.path}
                    className="small text-end">
                    Lost password?
                  </Card.Link>
                </div>
              </Form.Group>

              <ButtonLoading
                loading={loading}
                innerText={'Sign in'}
              />
            </Form>
            {/* <div className="d-flex justify-content-center align-items-center mt-4">
              <span className="fw-normal">
                Not registered?
                <Card.Link
                  as={Link}
                  to={Routes.Signup.path}
                  className="fw-bold"
                >
                  {` Create account `}
                </Card.Link>
              </span>
            </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
