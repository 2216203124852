import { faHeadset, faUserTie, faUserGroup, faHome, faTachometer, faTruck, faCar, faShield } from "@fortawesome/free-solid-svg-icons";
import Routes from "../routes";
import { Enums } from "./Enums";

export const SidebarData = {
    dashboard: {
        title: "Dashboard",
        icon: faTachometer,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.DashboardOverview.path,
        has_children: false,
        active_links: [Routes.DashboardOverview.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    auto_list: {
        title: "Auto",
        icon: faCar,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.AutoList.path,
        has_children: false,
        active_links: [Routes.AutoList.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    business_owners_list: {
        title: "Business Owner",
        icon: faUserTie,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.BusinessOwners.path,
        has_children: false,
        active_links: [Routes.BusinessOwners.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    home_owners_list: {
        title: "Home Owner",
        icon: faHome,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.HomeOwnerList.path,
        has_children: false,
        active_links: [Routes.HomeOwnerList.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    general_liability_list: {
        title: "General Liability",
        icon: faShield,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.GeneralLiability.path,
        has_children: false,
        active_links: [Routes.GeneralLiability.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    commercial_auto_list: {
        title: "Commercial Auto",
        icon: faTruck,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.CommercialAuto.path,
        has_children: false,
        active_links: [Routes.CommercialAuto.path, Routes.CommercialAutoView.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
    agent: {
        title: "Agent",
        icon: faHeadset,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.AgentList.path,
        has_children: false,
        active_links: [Routes.AgentList.path, Routes.AgentAdd.path, Routes.AgentDetails.path, Routes.AgentView.path],
        include_users: [Enums.USER_TYPES.admin, Enums.USER_TYPES.super_admin],
    },
    customer: {
        title: "Customer",
        icon: faUserGroup,
        image: '',
        target: "_self",
        badgeText: '',
        external: false,
        link: Routes.CustomerList.path,
        has_children: false,
        active_links: [Routes.CustomerList.path],
        include_users: Object.values(Enums.USER_TYPES)
    },
};
