import React from "react";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

export default function ForgotPassword() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <h3>Forgot your password?</h3>
            <p className="mb-4">
              Don't fret! Just type in your email and we will send you a code to
              reset your password!
            </p>
            <Form>
              <div className="mb-4">
                <Form.Label htmlFor="email">Your Email</Form.Label>
                <InputGroup id="email">
                  <Form.Control
                    required
                    autoFocus
                    type="email"
                    placeholder="john@company.com"
                  />
                </InputGroup>
              </div>
              <Button variant="primary" type="submit" className="w-100">
                Recover password
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
