export const Enums = {

    MARITAL_STATUS: {

        'single': 'single',
        'married': 'married',
        'widowed': 'widowed',
        'divorced': 'divorced',
        'separated': 'separated'

    },
    GENDER_VALUES: {
        'male': 'Male',
        'female': 'Female',
        'other': 'Other'
    },
    USER_TYPES: {
        'super_admin': 'super_admin',
        'admin': 'admin',
        'agent': 'agent',
        'customer': 'customer'
    },
    INSURANCE_SLUG: {
        'auto': 'auto',
        'business_owners': 'business-owners',
        'general_liability': 'general-liability',
        'commercial_auto': 'commercial-auto',
        'home_owners': 'home-owners'
    },
    INSURANCE_STATUS: {
        pending: 'pending',
        in_progress: 'in_progress',
        rejected: 'rejected',
        accepted: 'accepted',
        quotation_sent: 'quotation_sent',
        confirmed: 'confirmed',
        payment_completed: 'payment_completed',
        completed: 'completed',

    },
    INSURANCE_STATUS_VALUES: {
        pending: 'Pending',
        in_progress: 'In Progress',
        rejected: 'Rejected',
        accepted: 'Accepted',
        quotation_sent: 'Quotation Sent',
        confirmed: 'Confirmed',
        payment_completed: 'Payment Completed',
        completed: 'Completed',

    }
};
