import { Badge } from "react-bootstrap";
import { Enums } from "./Enums";
export const Badges = {

    INSURANCE_STATUS: {

        pending: <Badge className="py-2" bg="warning">{Enums.INSURANCE_STATUS_VALUES.pending}</Badge>,
        in_progress: <Badge className="py-2" bg="info">{Enums.INSURANCE_STATUS_VALUES.in_progress}</Badge>,
        rejected: <Badge className="py-2" bg="danger">{Enums.INSURANCE_STATUS_VALUES.rejected}</Badge>,
        accepted: <Badge className="py-2" bg="success">{Enums.INSURANCE_STATUS_VALUES.accepted}</Badge>,
        quotation_sent: <Badge className="py-2" bg="info">{Enums.INSURANCE_STATUS_VALUES.quotation_sent} </Badge>,
        confirmed: <Badge className="py-2" bg="success">{Enums.INSURANCE_STATUS_VALUES.confirmed}</Badge>,
        payment_completed: <Badge className="py-2" bg="success">{Enums.INSURANCE_STATUS_VALUES.payment_completed}</Badge>,
        completed: <Badge className="py-2" bg="primary">{Enums.INSURANCE_STATUS_VALUES.completed}</Badge>

    }
};