import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import Routes from "../routes"
import { commonHelper } from "../common/commonHelper"

const AppBreadcrumb = () => {
    const currentLocation = useLocation().pathname
    const navigate = useNavigate()

    const getBreadcrumbs = (location) => {
        let breadcrumbs = []
        let locationArray = location.split("/")
        let new_location_array = []
        locationArray.forEach((seg) => {
            new_location_array = [...new_location_array, seg.trim() ? (commonHelper.validateUuid(seg.trim()) ? ":id" : seg) : ""]
        })

        let new_location = new_location_array.join("/")
        let current_route_array_key = Object.keys(Routes).filter((key) => !Routes[key].is_independent && !Routes[key].is_auth && Routes[key].path === new_location)
        if (current_route_array_key.length) {
            let current_route_array = Routes[current_route_array_key[0]];
            breadcrumbs = current_route_array.breadcrumb
        }
        return breadcrumbs
    }

    const breadcrumbs = getBreadcrumbs(currentLocation)
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-3 ">
                <li className="breadcrumb-item cursor-pointer">
                    <button className="nav-link" href="" onClick={() => navigate(Routes.DashboardOverview.path)}>
                        <FontAwesomeIcon icon={faHome} />
                    </button>
                </li>
                {breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <li
                            key={index}
                            className={`breadcrumb-item cursor-pointer ${breadcrumb.active ? "active" : ""}`}
                        >
                            {!breadcrumb.active ? (
                                <button className="nav-link" onClick={() => navigate(breadcrumb.path)}>
                                    {breadcrumb.name}
                                </button>
                            ) : (
                                breadcrumb.name
                            )}
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

export default React.memo(AppBreadcrumb)
