import React, { useState, useEffect } from "react";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import AuthLayouts from "./layouts/AuthLayouts";
import Elements from "./Elements";
import Routes from "./routes";
import Navbar from "./components/Navbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/custom.scss';

export default function App() {

  // localStorage.removeItem('auth_token' , '234234')
  const UserData = useSelector((state) => state.data);
  const authToken =
    UserData && UserData.auth_token ? UserData.auth_token : false;

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  useEffect(() => {
    if (!authToken) {
      document.body.classList.add('auth-container');
    } else {
      document.body.classList.remove('auth-container');
    }
  }, [authToken]);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  let auth_routes_array = Object.values(Routes).filter((itm) => {
    if (itm.is_auth) {
      return itm;
    } else {
      return null;
    }
  });

  let app_routes_array = Object.values(Routes).filter((itm) => {
    if (!itm.is_auth) {
      return itm;
    } else {
      return null;
    }
  });

  let independent_routes_array = Object.values(Routes).filter((itm) => {
    if (itm.is_independent) {
      return itm;
    } else {
      return null;
    }
  });

  auth_routes_array = [...auth_routes_array, ...independent_routes_array];
  const authRoute = () => (

    <ReactRoutes>
      {auth_routes_array.map((item, i) => {
        return (
          <Route
            key={i}
            exact
            path={item.path}
            element={<AuthLayouts section_class={item.section_class} Component={Elements[item.element]} />}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" replace />} />
    </ReactRoutes>
  );

  const appRoute = () => (<>
    <Sidebar />
    <main className="content">
      <Navbar />
      <ReactRoutes>
        {app_routes_array.map((item, i) => {
          let Element = Elements[item.element]
          return (
            <Route
              key={i}
              exact
              path={item.path}
              element={<Element />}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </ReactRoutes>
      <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
    </main>
  </>);

  return <>{
    authToken ? appRoute() : authRoute()}
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>;
}
