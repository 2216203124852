import DashboardOverview from "./pages/dashboard/DashboardOverview";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Lock from "./pages/default/Lock";
import NotFoundPage from "./pages/default/NotFound";
import ServerError from "./pages/default/ServerError";
import AgentList from "./pages/agents/AgentList";
import AgentEdit from "./pages/agents/AgentEdit";
import AgentAdd from "./pages/agents/AgentAdd";
import CustomerList from "./pages/customers/CustomerList";
import AutoList from "./pages/insurances/auto/AutoList";
import BusinessOwners from "./pages/insurances/business-owners/BusinessOwners";
import HomeOwnerList from "./pages/insurances/home-owners/HomeOwnerList";
import GeneralLiability from "./pages/insurances/general-liability/GeneralLiability";
import CommercialAuto from "./pages/insurances/commercial-auto/CommercialAuto";
import AgentView from "./pages/agents/AgentView";
import CustomerView from "./pages/customers/CustomerView";
import AutoInsuranceView from "./pages/insurances/auto/AutoInsuranceView";
import BusinessOwnerView from "./pages/insurances/business-owners/BusinessOwnerView";
import HomeOwnerView from "./pages/insurances/home-owners/HomeOwnerView";
import GeneralLiabilityView from "./pages/insurances/general-liability/GeneralLiabilityView";
import CommercialAutoView from "./pages/insurances/commercial-auto/CommercialAutoView";

const Elements = {
    //Auth Elements
    Signin: Signin,
    Signup: Signup,
    ForgotPassword: ForgotPassword,
    ResetPassword: ResetPassword,
    Lock: Lock,
    NotFoundPage: NotFoundPage,
    ServerError: ServerError,
    //App Elements
    DashboardOverview: DashboardOverview,
    AgentList : AgentList,
    AgentEdit : AgentEdit,
    AgentAdd : AgentAdd,
    AgentView : AgentView,
    //Customers
    CustomerList : CustomerList,
    CustomerView : CustomerView,
    //Insurances
    AutoList: AutoList,
    BusinessOwners: BusinessOwners,
    HomeOwnerList : HomeOwnerList,
    GeneralLiability : GeneralLiability,
    CommercialAuto : CommercialAuto,
    AutoInsuranceView : AutoInsuranceView,
    BusinessOwnerView : BusinessOwnerView,
    HomeOwnerView : HomeOwnerView,
    GeneralLiabilityView: GeneralLiabilityView,
    CommercialAutoView : CommercialAutoView

    
};

export default Elements