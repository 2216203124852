
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes, faArrowsToDot as faList } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import DefaultUserImage from "../assets/Images/defaults/default-image.png";
import DefaultSiteLogo from "../assets/Images/defaults/new-cover-logo.png";
import Routes from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import { SidebarData } from "../constants/SidebarData";
import { useDispatch, useSelector } from "react-redux";
import { alerts } from "../common/alertService";
import { commonHelper } from "../common/commonHelper";

export default function Sidebar(props = {}) {
  const currentLocation = useLocation().pathname
  const UserData = useSelector((state) => state.data);
  let logged_user = UserData.logged_user;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const handleLogout = () => {
    localStorage.removeItem('user_id')
    localStorage.removeItem('auth_token')
    localStorage.removeItem('user')
    dispatch({ type: 'USER_ID', payload: null })
    dispatch({ type: 'AUTH_TOKEN', payload: null })
    dispatch({ type: 'LOGGED_USER', payload: null })
    alerts.notifySuccess("You are logged out successfully.")
    navigate(Routes.Signin.path)
  }

  const CollapsableNavItem = (props) => {
    const { activeLinks, eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";


    let locationArray = currentLocation.split("/")
    let new_location_array = []
    locationArray.forEach((seg) => {
      new_location_array = [...new_location_array, seg.trim() ? (commonHelper.validateUuid(seg.trim()) ? ":id" : seg.trim()) : ""]
    })
    let new_location = new_location_array.join("/")
    let is_selected = activeLinks.includes(new_location) ? true : false;

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className={`${is_selected && 'active'} d-flex accordion-link justify-content-between align-items-center`}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { activeLinks, isChild, title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    // const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    let locationArray = currentLocation.split("/")
    let new_location_array = []
    locationArray.forEach((seg) => {
      new_location_array = [...new_location_array, seg.trim() ? (commonHelper.validateUuid(seg.trim()) ? ":id" : seg.trim()) : ""]
    })
    let new_location = new_location_array.join("/")
    let is_selected = activeLinks.includes(new_location) ? true : false;
    const navItemClassName = is_selected ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
            {isChild && <FontAwesomeIcon icon={faList} className="me-2" />}<span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={true} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block theme-color-600 text-white`}>
          <div className="sidebar-upper px-2 pt-3 pb-1 d-flex justify-content-center">
            <Image src={
              logged_user.profile_img
                ? process.env.REACT_APP_ROOT_URL +
                logged_user.profile_img
                : DefaultSiteLogo
            } />
          </div>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={
                    logged_user.profile_img
                      ? process.env.REACT_APP_ROOT_URL +
                      logged_user.profile_img
                      : DefaultUserImage
                  } className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, {logged_user.display_name}</h6>
                  <Button as={Link} onClick={handleLogout} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {Object.keys(SidebarData).map((item, key) => {
                if (SidebarData[item].include_users.includes(logged_user.user_type)) {
                  if (SidebarData[item].has_children) {
                    return (<CollapsableNavItem key={key} activeLinks={SidebarData[item].active_links} eventKey={SidebarData[item].eventKey} title={SidebarData[item].title} icon={SidebarData[item].icon}>
                      {SidebarData[item].children.map((child_itm, inner_key) => {
                        return (<NavItem isChild={true} key={key + '-' + inner_key} badgeText={child_itm.badgeText} badgeBg={child_itm.badgeBg} badgeColor={child_itm.badgeColor} target={child_itm.target} image={child_itm.image} external={child_itm.external} title={child_itm.title} link={child_itm.link} icon={child_itm.icon} />)
                      })}
                    </CollapsableNavItem>)
                  } else {
                    return <NavItem key={key} activeLinks={SidebarData[item].active_links} isChild={false} badgeText={SidebarData[item].badgeText} badgeBg={SidebarData[item].badgeBg} badgeColor={SidebarData[item].badgeColor} target={SidebarData[item].target} image={SidebarData[item].image} external={SidebarData[item].external} title={SidebarData[item].title} link={SidebarData[item].link} icon={SidebarData[item].icon} />
                  }
                } else {
                  return null
                }
              })}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
