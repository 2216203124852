import React from 'react'
import Select from 'react-select';
import {
    Form,
    InputGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function SelectInput({ options, disabled = false, selected, inputClassName = "w-100", isMulti = false, isSearchable = false, onChange, inputGroupClass = "mb-4", id = null, is_required = false, Label = null, inputIcon = null, placeholder = 'Select...', validateError = "" }) {

    return (<Form.Group id={id ? id : null} className={`mb-4${inputGroupClass ? ` ${inputGroupClass}` : ""} `}>
        {Label ? <Form.Label>{Label} {is_required && <span className="text-danger">*</span>}</Form.Label> : null}
        <InputGroup>
            {inputIcon ? <InputGroup.Text>
                <FontAwesomeIcon icon={inputIcon} />
            </InputGroup.Text> : null}
            <Select
                placeholder={placeholder}
                isMulti={isMulti}
                isSearchable={isSearchable}
                className={inputClassName}
                value={selected}
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                disabled={disabled}
                onChange={(value) => {
                    onChange(value)
                }}
                options={options}
            />
        </InputGroup>
        {validateError && <span className="text-danger">{validateError} </span>}
    </Form.Group>)
}
