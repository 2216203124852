import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  Container,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Routes from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { commonHelper } from "../../common/commonHelper";
import InputElement from "../../components/Forms/InputElement";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { alerts } from "../../common/alertService";
import ButtonLoading from "../../components/Forms/ButtonLoading";
import SelectInput from "../../components/Forms/SelectInput";
import { Options } from "../../constants/Options";

export default function Signup() {

  document.title = `${process.env.REACT_APP_NAME} | Signup`;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    alternate_phone_number: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    password: "",
    confirm_password: "",
  })

  const [formInputsErrors, setFormInputsErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    password: "",
    confirm_password: "",
  })

  const validationError = {
    first_name: "First name is required",
    last_name: "Last name is required",
    email: "Email is required",
    phone_number: "Phone number is required",
    date_of_birth: "Date of birth is required",
    gender: "Gender is required",
    marital_status: "Marital status is required",
    password: "Password is required",
    confirm_password: "Confirm Password is required",
    validate_email: "Email is not valid",
    mismatch_password: 'password and confirm password must be same'
  }

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((er_key) => {
      errorJson = {
        ...errorJson,
        [er_key]: ""
      }
    })

    Object.keys(errorJson).forEach((form_er_key) => {

      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "email") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.email)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })
    if (isValidate) {
      if (formInputs['confirm_password'] !== formInputs['password']) {
        errorJson = {
          ...errorJson,
          'password': validationError.mismatch_password
        }
        isValidate = false;
      }
    }
    setFormInputsErrors(errorJson)
    return isValidate;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      let res = await APICall({
        url: ApiTypes.register,
        data: formInputs
      })
      if (res) {
        if (res.status === 1) {
          alerts.notifySuccess(res.message)
          navigate(Routes.Signin.path)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const handleOnChange = (name, value) => {
    setFormInputs({
      ...formInputs,
      [name]: value
    })
  }


  return (
    <Container>
      <Row
        className="justify-content-center form-bg-image"
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h3 className="mb-0">Create an account</h3>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)} className="mt-4">
              <InputElement
                is_required={true}
                Label={'First Name'}
                value={formInputs.first_name}
                name="first_name"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="First Name"
                validateError={formInputsErrors.first_name}
              />
              <InputElement
                is_required={true}
                Label={'Last Name'}
                value={formInputs.last_name}
                name="last_name"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="Last Name"
                validateError={formInputsErrors.last_name}
              />
              <InputElement
                is_required={true}
                Label={'Email Address'}
                value={formInputs.email}
                name="email"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="Email Address"
                validateError={formInputsErrors.email}
              />
              <InputElement
                is_required={true}
                Label={'Phone Number'}
                value={formInputs.phone_number}
                name="phone_number"
                maxLength={16}
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="number"
                placeholder="Phone Number"
                validateError={formInputsErrors.phone_number}
              />
              <InputElement
                Label={'Alternate Phone Number'}
                value={formInputs.alternate_phone_number}
                name="alternate_phone_number"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                maxLength={16}
                type="number"
                placeholder="Alternate Phone Number"
              />
              <InputElement
                is_required={true}
                Label={'Date Of Birth'}
                value={formInputs.date_of_birth}
                name="date_of_birth"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="date"
                max={commonHelper.formatDate(new Date(), 'YYYY-MM-DD' ,'')}
                placeholder="Date Of Birth"
                validateError={formInputsErrors.date_of_birth}
              />
              <SelectInput
                is_required={true}
                placeholder={'Select Gender'}
                selected={Options.genders.filter((itm) => itm.value === formInputs.gender)[0]}
                Label={"Select Gender"}
                options={Options.genders}
                onChange={(item) => {
                  handleOnChange('gender', item.value)
                }}
                validateError={formInputsErrors.gender}
              />
              <SelectInput
                is_required={true}
                placeholder={'Select Marital Status'}
                selected={Options.marital_status.filter((itm) => itm.value === formInputs.marital_status)[0]}
                Label={"Select Marital Status"}
                options={Options.marital_status}
                onChange={(item) => {
                  handleOnChange('marital_status', item.value)
                }}
                validateError={formInputsErrors.marital_status}
              />
              <InputElement
                Label={'Address'}
                value={formInputs.address}
                name="address"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="Address"
              />
              <InputElement
                Label={'City'}
                value={formInputs.city}
                name="city"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="City"
              />
              <InputElement
                Label={'State'}
                value={formInputs.state}
                name="state"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="State"
              />
              <InputElement
                Label={'Country'}
                value={formInputs.country}
                name="country"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="Country"
              />
              <InputElement
                Label={'ZIP Code'}
                value={formInputs.zipcode}
                name="zipcode"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="text"
                placeholder="ZIP Code"
              />
              <InputElement
                is_required={true}
                Label={'Password'}
                value={formInputs.password}
                name="password"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="password"
                placeholder="Password"
                validateError={formInputsErrors.password}
              />
              <InputElement
                is_required={true}
                Label={'Confirm Password'}
                value={formInputs.confirm_password}
                name="confirm_password"
                onChange={(name, value) => {
                  handleOnChange(name, value)
                }}
                type="password"
                placeholder="Confirm Password"
                validateError={formInputsErrors.confirm_password}
              />
              <ButtonLoading
                loading={loading}
                innerText={'Sign up'}
              />
            </Form>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <span className="fw-normal">
                Already have an account?
                <Card.Link
                  as={Link}
                  to={Routes.Signin.path}
                  className="fw-bold"
                >
                  {` Login here `}
                </Card.Link>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
