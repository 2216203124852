import {

    Button,
    Spinner
} from "@themesberg/react-bootstrap";

export default function ButtonLoading({ loading, innerText, className = 'w-100' }) {
    if (loading) {
        return (<Button variant="theme" type="submit" className={className}>
            <Spinner size="sm" animation="border" /> <span className="ms-2">Loading...</span>
        </Button>)
    } else {
        return (<Button variant="theme" type="submit" className={className}>
            {innerText}
        </Button>)
    }
}
