const Routes = {
    // Auth routes
    Signin: { is_independent: false, is_auth: true, element: 'Signin', path: "/", section_class: null },
    Signup: { is_independent: false, is_auth: true, element: 'Signup', path: "/sign-up" },
    ForgotPassword: { is_independent: false, is_auth: true, element: 'ForgotPassword', path: "/forgot-password", section_class: 'vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center' },
    ResetPassword: { is_independent: false, is_auth: true, element: 'ResetPassword', path: "/reset-password", section_class: 'bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5' },
    Lock: { is_independent: true, is_auth: false, element: 'Lock', path: "/lock" },
    NotFound: { is_independent: true, is_auth: false, element: 'NotFoundPage', path: "/404" },
    ServerError: { is_independent: true, is_auth: false, element: 'ServerError', path: "/500" },
    //Dashboard
    DashboardOverview: { is_independent: false, is_auth: false, element: 'DashboardOverview', path: "/", breadcrumb: [] },
    //Agent
    AgentList: {
        is_independent: false, is_auth: false, element: 'AgentList', path: "/agent/list", breadcrumb: [
            {
                name: "Agent List",
                path: "/agent/list",
                active: true,
            },]
    },
    AgentDetails: {
        is_independent: false, is_auth: false, element: 'AgentEdit', path: "/agent/details/:id", breadcrumb: [
            {
                name: "Agent List",
                path: "/agent/list",
                active: false,
            },
            {
                name: "Agent Details",
                path: "/agent/details/:id",
                active: true,
            }
        ]
    },
    AgentAdd: {
        is_independent: false, is_auth: false, element: 'AgentAdd', path: "/agent/store", breadcrumb: [
            {
                name: "Agent List",
                path: "/agent/list",
                active: false,
            },
            {
                name: "Add Agent",
                path: "/agent/store",
                active: true,
            }
        ]
    },
    AgentView: {
        is_independent: false, is_auth: false, element: 'AgentView', path: "/agent/show/:id", breadcrumb: [
            {
                name: "Agent List",
                path: "/agent/list",
                active: false,
            },
            {
                name: "Agent Views",
                path: "/agent/show/:id",
                active: true,
            }
        ]
    },
    // Customer
    CustomerList: {
        is_independent: false, is_auth: false, element: 'CustomerList', path: "/customers", breadcrumb: [

            {
                name: "Customer List",
                path: "/customer/list",
                active: true,
            }
        ]
    },
    CustomerView: {
        is_independent: false, is_auth: false, element: 'CustomerView', path: "/customer/details/:id", breadcrumb: [

            {
                name: "Customer List",
                path: "/customer/list",
                active: false,
            },
            {
                name: "Customer Details",
                path: "/customer/details/:id",
                active: true,
            }
        ]
    },
    // Insurance
    AutoList: {
        is_independent: false, is_auth: false, element: 'AutoList', path: "/auto/list", breadcrumb: [
            {
                name: "Auto Quotation List",
                path: "/auto/list",
                active: true,
            }
        ]
    },
    AutoInsuranceView: {
        is_independent: false, is_auth: false, element: 'AutoInsuranceView', path: "/auto/details/:id", breadcrumb: [
            {
                name: "Auto Quotation List",
                path: "/auto/list",
                active: false,
            },
            {
                name: "Auto Quotation Details",
                path: "/auto/details/:id",
                active: true,
            }
        ]
    },
    BusinessOwners: {
        is_independent: false, is_auth: false, element: 'BusinessOwners', path: "/business-owners/list", breadcrumb: [
            {
                name: "Business Owner Quotation List",
                path: "/business-owners/list",
                active: true,
            }
        ]
    },
    BusinessOwnerView: {
        is_independent: false, is_auth: false, element: 'BusinessOwnerView', path: "/business-owners/details/:id", breadcrumb: [
            {
                name: "Business Owner Quotation List",
                path: "/business-owners/list",
                active: false,
            },
            {
                name: "Business Owner Quotation Details",
                path: "/business-owners/details/:id",
                active: true,
            }
        ]
    },
    HomeOwnerList: {
        is_independent: false, is_auth: false, element: 'HomeOwnerList', path: "/home-owners/list", breadcrumb: [
            {
                name: "Home Owner Quotation List",
                path: "/home-owners/list",
                active: true,
            }
        ]
    },
    HomeOwnerView: {
        is_independent: false, is_auth: false, element: 'HomeOwnerView', path: "/home-owners/details/:id", breadcrumb: [
            {
                name: "Home Owner Quotation List",
                path: "/home-owners/list",
                active: false,
            },
            {
                name: "Home Owner Quotation Details",
                path: "/home-owners/details/:id",
                active: true,
            }
        ]
    },
    GeneralLiability: {
        is_independent: false, is_auth: false, element: 'GeneralLiability', path: "/general-liability/list", breadcrumb: [
            {
                name: "General Liability Quotation List",
                path: "/general-liability/list",
                active: true,
            }
        ]
    },
    GeneralLiabilityView: {
        is_independent: false, is_auth: false, element: 'GeneralLiabilityView', path: "/general-liability/details/:id", breadcrumb: [
            {
                name: "General Liability Quotation List",
                path: "/general-liability/list",
                active: false,
            },
            {
                name: "General Liability Quotation Details",
                path: "/general-liability/details/:id",
                active: true,
            }
        ]
    },
    CommercialAuto: {
        is_independent: false, is_auth: false, element: 'CommercialAuto', path: "/commercial-auto/list", breadcrumb: [
            {
                name: "Commercial Auto Quotation List",
                path: "/commercial-auto/list",
                active: true,
            }
        ]
    },
    CommercialAutoView: {
        is_independent: false, is_auth: false, element: 'CommercialAutoView', path: "/commercial-auto/details/:id", breadcrumb: [
            {
                name: "Commercial Auto Quotation List",
                path: "/commercial-auto/list",
                active: false,
            },
            {
                name: "Commercial Auto Quotation Details",
                path: "/commercial-auto/details/:id",
                active: true,
            }
        ]
    },


};

export default Routes